/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

.table td {
    padding: 0.25rem;
}

code {
    color: #E01A76;
}

.customBorder {
    border: 5px solid green;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.form-padding {
    margin: 5px;
}

.form-pad-top {
    margin-top: 5px;
}

.form-pad-right {
    margin-right: 5px;
}

.chessboard-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
    background-color: white;
}

/* Overriding the default padding for CardHeader to make it not so tall*/
.card-header {
    padding: 2px;
}

.collapse-btn {
    width: 100%;
    background-color: lightgray;
    text-align: left;
}

.spinner-border {
    width: 1.1rem;
    height: 1.1rem;
}

.inline-btn {
    padding: 5px;
    margin-top: -5px;
    margin-left: 10px;
}

.brown-text {
    color: saddlebrown;
}

.purple-text {
    color: rebeccapurple;
}


/* End of Vertical Bar styles */
.App {
    font-family: sans-serif;
    text-align: center;
}

.progress {
    background-color: black;
    border-radius: 3px;
    box-shadow: none;
}

    .progress.vertical {
        position: relative;
        width: 30px;
        height: 230px;
        display: inline-block;
        margin-right: 10px;
    }

.progress {
    height: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: black;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

    .progress.vertical > .progress-bar {
        width: 100% !important;
        position: absolute;
        bottom: 0;
    }

.progress-bar {
    background-color: white;
    box-shadow: none;
}

.progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #337ab7;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-transition: width 0.6s ease;
    -o-transition: width 0.6s ease;
    transition: width 0.6s ease;
}
/* End of Vertical Bar styles */

/* Dropdown Button */
.dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 360px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

    /* Links inside the dropdown */
    .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

        /* Change color of dropdown links on hover */
        .dropdown-content a:hover {
            background-color: #ddd;
        }

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}

/* Start of white-black eval gauge */
.eval-gauge {
    width: var(--gauge-gap);
    position: relative;
    background: #fff;
    overflow: hidden;
    height:560px;
}

/*@media (max-width: 799px) and (orientation: portrait) {
    .eval-gauge {
        display: none;
    }
}*/

.eval-gauge.reverse {
    transform: rotateX(180deg)
}

.eval-gauge::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 5px rgba(0,0,0,0.7) inset
}

.eval-gauge tick {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px ridge #eee;
    opacity: 0.4
}

    .eval-gauge tick.zero {
        top: 6px;
        opacity: 1;
        border-bottom: 7px solid rgba(214,79,0,0.4);
        margin-top: -3px
    }

.eval-gauge .black {
    width: 100%;
    height: 50%;
    background: #888;
    transition: height 1s
}

.td-align-top {
    vertical-align:top;
}
/* End of white-black eval gauge */
